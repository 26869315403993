import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Navbar, Nav, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import useSidebar from "../../hooks/useSidebar";
import NavbarUser from "./NavbarUser";
import useAppDispatch from "../../hooks/useAppDispatch";
import { setTitleEvent, eventList } from "../../redux/slices/events";

function debounce(func, delay) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

const NavbarComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isOpen, setIsOpen } = useSidebar();
  const [navSearch, setNavSearch] = useState()

  const handleSearch = debounce((e) => {
    const searchTerm = e.target.value;
    dispatch(setTitleEvent(searchTerm));
    dispatch(eventList());

    if (location.pathname !== "/") {
      navigate("/");
    }
  }, 500); // 300ms delay

  useEffect(() => {
    if (location.pathname !== "/") {
      setNavSearch('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <Navbar variant="light" expand className="navbar-bg">
      <span
        className="sidebar-toggle d-flex"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <i className="hamburger align-self-center" />
      </span>
      <Form inline="true" className="d-none d-sm-inline-block">
        <InputGroup className="input-group-navbar">
          <Form.Control
            value={navSearch}
            placeholder={t("Search")}
            aria-label="Search"
            onChange={(e) => {
              setNavSearch(e.target.value);
              handleSearch(e)
            }}
          />
          <Button className="cursor-pointer" variant="" onClick={handleSearch}>
            <FontAwesomeIcon icon={faSearch} className="me-2" size="lg" />
          </Button>
        </InputGroup>
      </Form>
      <Navbar.Collapse>
        <Nav className="navbar-align">
          <NavbarUser />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
