
import React, {
    useEffect,
    useState,
    useContext
} from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import {
    Container,
    Button,
    Col,
    Breadcrumb,
    Card,
    Form,
    Row,
    OverlayTrigger, Tooltip
} from "react-bootstrap";
import { ColumnSortingTable } from "../../components/Table.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faSync, faTrash } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/Loader.js";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import
import NotyfContext from "../../contexts/NotyfContext.js";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { confirmAlert } from "react-confirm-alert";
import { ShowConfirm } from "../../components/Alerts.js";
import useAppDispatch from "../../hooks/useAppDispatch";
import useLayout from "../../hooks/useLayout.js";
import {
    eventList
} from "../../redux/slices/events";
import {
    SelectColumnFilter,
    generateDateCategories,
    NumberRangeColumnFilter
} from "../../assets/functions.js";
import { statusX } from "../../components/Events.js";
import { processData, generateAccessData } from '../../components/EventHistory'

export const EventHistory01 = ({ dataTable, setShowHistory }) => {
    const [historyData, setHistoryData] = useState(false);
    const [updateDataTable, setDataTableUpdate] = useState(false);
    const [updateDataTableWarning, setDataTableUpdateWarning] = useState(false);
    const [triggerExport, setTriggerExport] = useState(false);
    const [triggerExportResult, setTriggerExportResult] = useState(false);
    const userState = useAuth();
    const { setLoading } = useLayout();
    const notyf = useContext(NotyfContext);
    const days = generateDateCategories()
    const [filter, setFilter] = useState({ customizacao_id: null });
    const [fields, setFields] = useState(1);
    const [fieldsResult, setFieldsResult] = useState([]);
    const dispatch = useAppDispatch();

    const options = {
        chart: {
            zoom: {
                enabled: false,
            },
            toolbar: {
                export: {
                    csv: {
                        filename: dataTable.titulo + ' - Gráfico',
                    },
                    svg: {
                        filename: dataTable.titulo + ' - Gráfico',
                    },
                    png: {
                        filename: dataTable.titulo + ' - Gráfico',
                    }
                }
            }
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [5, 7, 5],
            curve: "straight",
            dashArray: [0, 8, 5],
        },
        markers: {
            size: 0,
            style: "hollow", // full, hollow, inverted
        },
        xaxis: {
            categories: days,
        },
        colors: [
            `#4ba700`
        ],
        tooltip: {
            y: [
                {
                    title: {
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
            ],
        },
        grid: {
            borderColor: "#f1f1f1",
        },
    };

    function handleDelete(props, platform) {
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return (
                    <ShowConfirm
                        title="Confirmar ação"
                        description="Deseja remover esse participante? Todos os dados relacionados ao usuário serão excluídos."
                        action={deletParticipant}
                        onClose={onClose}
                        param={{ props, platform }}
                    />
                );
            },
        });
    }

    function handleDeleteResult(props, platform) {
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return (
                    <ShowConfirm
                        title="Confirmar ação"
                        description="Deseja remover esse participante? Todos os dados relacionados ao usuário serão excluídos."
                        action={() => alert('Função indisponível')}
                        onClose={onClose}
                        param={{ props, platform }}
                    />
                );
            },
        });
    }

    function deletParticipant({ props, platform }) {
        setLoading(true)
        axios
            .delete(`/api/history/${props}`, {
                data: { platform: platform }
            })
            .then(function (response) {
                setDataTableUpdate((prevCount) => prevCount + 1);
                setLoading(false)
            })
            .catch(function (error) {
                setLoading(false)
                notyf.open({
                    type: "danger",
                    message: "Houve um erro, tente novamente",
                    ripple: true,
                    dismissible: true,
                });
            });
    }

    useEffect(() => {
        let getIndexApp = dataTable.aplicativos.findIndex(field => field.customizacao_id == filter.customizacao_id);
        let applicationType = getIndexApp >= 0 ? dataTable.aplicativos[getIndexApp].aplicativo_id : null
        axios.get(`/api/event/participants/${dataTable.evento_id}?platform=${dataTable.platform}&customizacao_id=${filter.customizacao_id}&type=${applicationType}`)
            .then(function (response) {
                let currentApp
                if (applicationType !== null && dataTable.aplicativos[getIndexApp]) {
                    currentApp = dataTable.aplicativos[getIndexApp].json
                }
                setHeaders(dataTable, setFields, userState, setTriggerExport, handleDelete)
                setHeadersResult(dataTable, setFieldsResult, userState, setTriggerExportResult, handleDeleteResult)
                let data = processData(dataTable, response.data, currentApp, applicationType, setFields, setFieldsResult)
                console.log(data)
                setHistoryData(data);
                if (updateDataTableWarning === "update") {
                    setDataTableUpdateWarning(false);
                    notyf.open({
                        type: "success",
                        message: "histórico atualizado com sucesso",
                        ripple: true,
                        dismissible: true,
                    });
                }
                setLoading(false)
            })
            .catch(function (error) {
                setLoading(false)
                notyf.open({
                    type: "danger",
                    message: "Houve um erro, tente novamente",
                    ripple: true,
                    dismissible: true,
                });
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateDataTable, filter, setFields]);

    //Loading icon
    if (historyData === false || historyData === undefined || fields.length === 0) {
        return <Loader></Loader>;
    }

    const data = generateAccessData(historyData.participants)

    return (
        <Container fluid className="p-0">
            <Button
                variant="primary"
                className="float-end me-2"
                onClick={() => {
                    setDataTableUpdate((prevCount) => prevCount + 1);
                    setDataTableUpdateWarning("update");
                    setLoading(true)
                }}
            >
                <FontAwesomeIcon icon={faSync} />
            </Button>
            <Button
                variant="primary"
                className="float-end me-2"
                onClick={() => {
                    if (userState.plataforma_aplicativos === 1) {
                        setShowHistory(false)
                    } else {
                        dispatch(eventList());
                    }
                }}
            >
                Voltar
            </Button>
            <Breadcrumb>
                <Breadcrumb.Item
                    onClick={() => {
                        dispatch(eventList());
                    }}
                >
                    Eventos
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Editar evento
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Histórico de utilização
                </Breadcrumb.Item>
            </Breadcrumb>
            <h6 className="text-muted mb-3">
                Consulte os dados coletados durante o evento
            </h6>
            <Card>
                <Card.Body>
                    <p className="mb-0"><b>Evento: </b>{dataTable.titulo}</p>
                    <p className="mb-0"><b>Status: </b>{statusX[dataTable.status]}</p>
                    <p className="mb-0"><b>Duração: </b>{dataTable.data_criacao} até {dataTable.data_termino}</p>
                </Card.Body>
            </Card>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">
                    Total de registros ({historyData.participants.length})</h5>
            </Form.Group>
            <Card>
                <Card.Body>
                    <div className="chart">
                        <Chart options={options} series={data} type="line" height="350" />
                    </div>
                </Card.Body>
            </Card>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">Lista de participantes</h5>
            </Form.Group>
            <Row className="mx-0">
                <Form.Group
                    as={Col}
                    className={"mb-3"}
                    sm={"4"}
                    controlId="validationFormik03">
                    <Form.Label>Selecione um aplicativo</Form.Label>
                    <Form.Select
                        value={filter.app}
                        onChange={(e) => {
                            setLoading(true)
                            setFilter((prevState) => ({
                                ...prevState,
                                customizacao_id: e.target.value
                            }))
                        }}
                    >
                        <option value={null}>
                            Nenhum
                        </option>
                        {dataTable.aplicativos.map((element, index) => {
                            return (
                                <option key={"fieldType" + index} value={element.customizacao_id}>
                                    {element.titulo}
                                </option>
                            );
                        })}
                    </Form.Select>
                </Form.Group>
            </Row>
            <Card>
                <Card.Body className="overflow-auto">
                    <ColumnSortingTable
                        columns={fields}
                        data={historyData.history}
                        getCellProps={(cellinfo) => ({
                            style: {
                                textAlign: cellinfo.column.id === "icons" || cellinfo.column.id === "media" || cellinfo.column.id === "time" || cellinfo.column.id === "score" || cellinfo.column.id === "currentQuestion" ? "center" : "",
                                width: cellinfo.column.id === "icons" || cellinfo.column.id === "media" ? "60px" : "",
                            }
                        })}
                        triggerExport={triggerExport}
                        setTriggerExport={setTriggerExport}
                    />
                </Card.Body>
            </Card>
            {console.log(historyData.results)}
            {fieldsResult.length > 0 && filter.customizacao_id > 0 &&
                <>
                    <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                        <h5 className="card-title mb-0">Resultados</h5>
                    </Form.Group>
                    <Card>
                        <Card.Body className="overflow-auto">
                            <ColumnSortingTable
                                columns={fieldsResult}
                                data={historyData.results}
                                getCellProps={(cellinfo) => ({
                                    style: {
                                        textAlign: cellinfo.column.id === "icons" || cellinfo.column.id === "media" || cellinfo.column.id === "time" || cellinfo.column.id === "score" || cellinfo.column.id === "currentQuestion" ? "center" : "",
                                        width: cellinfo.column.id === "icons" || cellinfo.column.id === "media" ? "60px" : "",
                                    }
                                })}
                                triggerExport={triggerExportResult}
                                setTriggerExport={setTriggerExportResult}
                            />
                        </Card.Body>
                    </Card>
                </>}
        </Container>
    );
};

export const EventHistory02 = ({ dataTable, setShowHistory }) => {
    const [historyData, setHistoryData] = useState(false);
    const [updateDataTable, setDataTableUpdate] = useState(false);
    const [updateDataTableWarning, setDataTableUpdateWarning] = useState(false);
    const [triggerExport, setTriggerExport] = useState(false);
    const userState = useAuth();
    const { setLoading } = useLayout();
    const notyf = useContext(NotyfContext);
    const navigate = useNavigate();
    const days = generateDateCategories()
    const [chartKey, setChartKey] = useState(0);
    const [filter, setFilter] = useState({ customizacao_id: null });
    const [fields, setFields] = useState([]);

    const options = {
        chart: {
            zoom: {
                enabled: false,
            },
            toolbar: {
                export: {
                    csv: {
                        filename: dataTable.titulo + ' - Gráfico',
                    },
                    svg: {
                        filename: dataTable.titulo + ' - Gráfico',
                    },
                    png: {
                        filename: dataTable.titulo + ' - Gráfico',
                    }
                }
            }
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [5, 7, 5],
            curve: "straight",
            dashArray: [0, 8, 5],
        },
        markers: {
            size: 0,
            style: "hollow", // full, hollow, inverted
        },
        xaxis: {
            categories: days,
        },
        colors: [
            `#4ba700`
        ],
        tooltip: {
            y: [
                {
                    title: {
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
            ],
        },
        grid: {
            borderColor: "#f1f1f1",
        },
    };

    function handleDelete(props, platform) {
        confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return (
                    <ShowConfirm
                        title="Confirmar ação"
                        description="Deseja remover esse participante? Todos os dados relacionados ao usuário serão excluídos."
                        action={deletParticipant}
                        onClose={onClose}
                        param={{ props, platform }}
                    />
                );
            },
        });
    }

    function deletParticipant({ props, platform }) {
        setLoading(true)
        axios
            .delete(`/api/history/${props}`, {
                data: { platform: platform }
            })
            .then(function (response) {
                setDataTableUpdate((prevCount) => prevCount + 1);
                setLoading(false)
            })
            .catch(function (error) {
                setLoading(false)
                notyf.open({
                    type: "danger",
                    message: "Houve um erro, tente novamente",
                    ripple: true,
                    dismissible: true,
                });
            });
    }

    useEffect(() => {
        let getIndexApp = dataTable.aplicativos.findIndex(field => field.customizacao_id == filter.customizacao_id);
        let applicationType = getIndexApp >= 0 ? dataTable.aplicativos[getIndexApp].aplicativo_id : null
        axios.get(`/api/event/participants/${dataTable.evento_id}?platform=${dataTable.platform}&customizacao_id=${filter.customizacao_id}&type=${applicationType}`)
            .then(function (response) {
                let currentApp
                if (applicationType !== null && dataTable.aplicativos[getIndexApp]) {
                    currentApp = dataTable.aplicativos[getIndexApp].json
                }
                setHeaders(dataTable, setFields, userState, setTriggerExport, handleDelete)
                let data = processData(response.data.participants, response.data.history, currentApp, applicationType, setFields)
                setHistoryData(data);
                if (updateDataTableWarning === "update") {
                    setDataTableUpdateWarning(false);
                    notyf.open({
                        type: "success",
                        message: "histórico atualizado com sucesso",
                        ripple: true,
                        dismissible: true,
                    });
                }
                setLoading(false)
            })
            .catch(function (error) {
                setLoading(false)
                notyf.open({
                    type: "danger",
                    message: "Houve um erro, tente novamente",
                    ripple: true,
                    dismissible: true,
                });
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateDataTable, filter, setFields]);

    //Loading icon
    if (historyData === false || historyData === undefined || fields === undefined) {
        return <Loader></Loader>;
    }

    const data = generateAccessData(historyData.participants)

    return (
        <Container fluid className="p-0">
            <Button
                variant="primary"
                className="float-end me-2"
                onClick={() => {
                    setDataTableUpdate((prevCount) => prevCount + 1);
                    setDataTableUpdateWarning("update");
                    setLoading(true)
                }}
            >
                <FontAwesomeIcon icon={faSync} />
            </Button>
            <Button
                variant="primary"
                className="float-end me-2"
                onClick={() => {
                    setShowHistory(false)
                }}
            >
                Voltar
            </Button>
            <Breadcrumb>
                <Breadcrumb.Item
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    Eventos
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Histórico de utilização
                </Breadcrumb.Item>
            </Breadcrumb>
            <h6 className="text-muted mb-3">
                Consulte os dados coletados durante o evento
            </h6>
            <Card>
                <Card.Body>
                    <p className="mb-0"><b>Evento: </b>{dataTable.titulo}</p>
                    <p className="mb-0"><b>Status: </b>{statusX[dataTable.status]}</p>
                    <p className="mb-0"><b>Duração: </b>{dataTable.data_criacao} até {dataTable.data_termino}</p>
                </Card.Body>
            </Card>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">
                    Total de registros ({historyData.participants.length})</h5>
            </Form.Group>
            <Card>
                <Card.Body>
                    <div className="chart">
                        <Chart options={options} series={data} type="line" height="350" />
                    </div>
                </Card.Body>
            </Card>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">Lista de participantes</h5>
            </Form.Group>
            <Row className="mx-0">
                <Form.Group
                    as={Col}
                    className={"mb-3"}
                    sm={"4"}
                    controlId="validationFormik03">
                    <Form.Label>Selecione um aplicativo</Form.Label>
                    <Form.Select
                        value={filter.app}
                        onChange={(e) => {
                            setLoading(true)
                            setFilter((prevState) => ({
                                ...prevState,
                                customizacao_id: e.target.value
                            }))
                        }}
                    >
                        <option value={null}>
                            Nenhum
                        </option>
                        {dataTable.aplicativos.map((element, index) => {
                            return (
                                <option key={"fieldType" + index} value={element.customizacao_id}>
                                    {element.titulo}
                                </option>
                            );
                        })}
                    </Form.Select>
                </Form.Group>
            </Row>
            <Card>
                <Card.Body className="overflow-auto">
                    <ColumnSortingTable
                        columns={fields}
                        data={historyData.history}
                        title={dataTable.titulo}
                        getCellProps={(cellinfo) => ({
                            style: {
                                textAlign: cellinfo.column.id === "icons" || cellinfo.column.id === "media" || cellinfo.column.id === "time" || cellinfo.column.id === "score" || cellinfo.column.id === "currentQuestion" ? "center" : "",
                                width: cellinfo.column.id === "icons" || cellinfo.column.id === "media" ? "60px" : "",
                            }
                        })}
                        triggerExport={triggerExport}
                        setTriggerExport={setTriggerExport}
                    />
                </Card.Body>
            </Card>
        </Container>
    );
};

function setHeadersResult(dataTable, setFieldsResult, userState, setTriggerExportResult, handleDeleteResult) {
    const newFields = [];
    newFields.push({
        Header: (
            <OverlayTrigger overlay={<Tooltip>Exportar</Tooltip>}>
                <Button
                    variant="primary"
                    className="float-end"
                    onClick={() => {
                        setTriggerExportResult(true);
                    }}
                >
                    <FontAwesomeIcon className="cursor-pointer" size={'lg'} icon={faFileExport} />
                </Button>
            </OverlayTrigger>
        ),
        accessor: 'icons',
        disableSortBy: true,
        Filter: false,
        Cell: (cell) => {
            return (
                <>
                    {userState.plataforma_aplicativos === 1 && <OverlayTrigger overlay={<Tooltip>Excluir resultado</Tooltip>}>
                        <div
                            className="cursor-pointer"
                            onClick={() => handleDeleteResult(cell.row.original.resultado_id, dataTable.platform)}
                        >
                            <FontAwesomeIcon icon={faTrash} size={'lg'} />
                        </div>
                    </OverlayTrigger>}
                </>
            );
        },
    });
    setFieldsResult(newFields);
}


function setHeaders(dataTable, setFields, userState, setTriggerExport, handleDelete) {
    const newFields = [];
    dataTable.json.form.fields.forEach((element) => {
        let maxLength = 40;
        let truncatedString = element.label.length > maxLength
            ? element.label.slice(0, maxLength - 3) + "..."
            : element.label;

        switch (element.type) {
            case 'textarea':
            case 'input':
                if (element.validation === 'number') {
                    newFields.push({
                        Header: truncatedString,
                        accessor: element.label,
                        Filter: NumberRangeColumnFilter,
                        filter: "between",
                    });
                } else {
                    newFields.push({
                        Header: truncatedString,
                        accessor: element.label,
                    });
                }
                break;
            case 'select':
                newFields.push({
                    Header: truncatedString,
                    accessor: element.label,
                    Filter: SelectColumnFilter,
                });
                break;
            default:
                newFields.push({
                    Header: truncatedString,
                    accessor: element.label,
                });
                break;
        }
    });
    newFields.push({ Header: "Data de cadastro", accessor: "data_visitacao_formatted" });
    newFields.push({
        Header: (
            <OverlayTrigger overlay={<Tooltip>Exportar</Tooltip>}>
                <Button
                    variant="primary"
                    className="float-end"
                    onClick={() => {
                        setTriggerExport(true);
                    }}
                >
                    <FontAwesomeIcon className="cursor-pointer" size={'lg'} icon={faFileExport} />
                </Button>
            </OverlayTrigger>
        ),
        accessor: 'icons',
        disableSortBy: true,
        Filter: false,
        Cell: (cell) => {
            return (
                <>
                    {userState.plataforma_aplicativos === 1 && <OverlayTrigger overlay={<Tooltip>Excluir participante</Tooltip>}>
                        <div
                            className="cursor-pointer"
                            onClick={() => handleDelete(cell.row.original.visitante_id, dataTable.platform)}
                        >
                            <FontAwesomeIcon icon={faTrash} size={'lg'} />
                        </div>
                    </OverlayTrigger>}
                </>
            );
        },
    });

    setFields(newFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
}