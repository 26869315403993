import React, { useState } from "react";
import {
    Col,
    Form,
    Row,
    Card
} from "react-bootstrap";
import DropzoneComponent from "../Dropzone.js";
import { domainConfig } from "../../config.js";
import PopoverPicker from "../../components/PopoverPicker.js";

const Photobooth = ({
    handleChange,
    values,
    setFieldValue,
    errors,
    touched,
    handlePreviewSuccess,
    handlePreviewError,
}) => {
    const [props, setProps] = useState({
        backgroundColor: values.json.apresentation?.textSide?.backgroundColor,
        textColor: values.json.apresentation?.textSide?.textColor
    });

    console.log(values)
    console.log(errors)
    return (
        <>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">Definições</h5>
            </Form.Group>

            <Row className="mx-0 mb-3">
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as={'textarea'}
                        name="titulo"
                        value={values.titulo}
                        onChange={handleChange}
                        isInvalid={!!errors.titulo}
                        isValid={touched.titulo && !errors.titulo}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.titulo}
                    </Form.Control.Feedback>
                </Form.Group>
                <p className="text-start fw-bold mt-3 mb-2">Tipo de mídia</p>
                <div className="d-flex text-start">
                    <Form.Group sm={3} className='mb-3'>
                        <Form.Check
                            className="mode"
                            checked={values.json.apresentation.configs.media === 'image' ? true : false}
                            type="checkbox"
                            name={"json.apresentation.configs.media"}
                            onChange={(e) => {
                                setFieldValue(
                                    'json.apresentation.configs.media', 'image'
                                );
                            }}
                            label={`Imagem`}
                            isInvalid={!!errors.json?.apresentation?.configs?.media}
                            feedback={errors.json?.apresentation?.configs?.media}
                            feedbackType="invalid">
                        </Form.Check>
                    </Form.Group>
                    <Form.Group sm={3} className='mb-3 ms-3'>
                        <Form.Check
                            className="mode"
                            disabled
                            type="checkbox"
                            name={"json.apresentation.configs.media"}
                            label={`Vídeo`}
                            isInvalid={!!errors.json?.apresentation?.configs?.media}
                            feedback={errors.json?.apresentation?.configs?.media}
                            feedbackType="invalid">
                        </Form.Check>
                    </Form.Group>
                </div>
                {values.json.media === 'video' && <Row className="text-start">
                    {values.json.media === 'video' && <Form.Group as={Col} sm={6} className='mb-3'>
                        <Form.Label>Duração do vídeo (segundos)</Form.Label>
                        <Form.Control
                            type="number"
                            name="json.apresentation.configs.videoDuration"
                            value={values.json.apresentation.configs.videoDuration}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                            isInvalid={!!errors.json?.apresentation?.configs?.videoDuration}
                        />
                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {errors.json?.apresentation?.configs?.videoDuration}
                        </Form.Control.Feedback>
                    </Form.Group>}
                </Row>}
                <p className="text-start fw-bold mt-3 mb-2">Formulário</p>
                <div className="d-flex text-start">
                    <Form.Group sm={3} className='mb-3'>
                        <Form.Check
                            className="mode"
                            checked={values.json.apresentation.configs.form === false ? true : false}
                            type="checkbox"
                            name={"json.apresentation.configs.form"}
                            onChange={(e) => {
                                setFieldValue(
                                    'json.apresentation.configs.form', false
                                );
                            }}
                            label={`Sem coleta`}
                            isInvalid={!!errors.json?.apresentation?.configs?.form}
                            feedback={errors.json?.apresentation?.configs?.form}
                            feedbackType="invalid">
                        </Form.Check>
                    </Form.Group>
                    <Form.Group sm={3} className='mb-3 ms-3'>
                        <Form.Check
                            className="mode"
                            checked={values.json.apresentation.configs.form === 1 ? true : false}
                            type="checkbox"
                            name={"json.apresentation.configs.form"}
                            onChange={(e) => {
                                setFieldValue(
                                    'json.apresentation.configs.form', 1
                                );
                            }}
                            label={`Antes do QRCode`}
                            isInvalid={!!errors.json?.apresentation?.configs?.form}
                            feedback={errors.json?.apresentation?.configs?.form}
                            feedbackType="invalid">
                        </Form.Check>
                    </Form.Group>
                    <Form.Group sm={3} className='mb-3 ms-3'>
                        <Form.Check
                            className="mode"
                            checked={values.json.apresentation.configs.form === 2 ? true : false}
                            type="checkbox"
                            name={"json.apresentation.configs.form"}
                            onChange={(e) => {
                                setFieldValue(
                                    'json.apresentation.configs.form', 2
                                );
                            }}
                            label={`Após o QRCode`}
                            isInvalid={!!errors.json?.apresentation?.configs?.form}
                            feedback={errors.json?.apresentation?.configs?.form}
                            feedbackType="invalid">
                        </Form.Check>
                    </Form.Group>
                </div>
                <p className="text-start fw-bold mt-3 mb-2">Galeria</p>
                <Row className="text-start">
                    <Form.Group as={Col} sm={6} className='mb-3'>
                        <Form.Label>Tempo entre slides (milesimos)</Form.Label>
                        <Form.Control
                            type="number"
                            name="json.apresentation.configs.galleryTransition"
                            value={values.json.apresentation.configs.galleryTransition}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                            isInvalid={!!errors.json?.apresentation?.configs?.galleryTransition}
                        />
                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {errors.json?.apresentation?.configs?.galleryTransition}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
            </Row>

            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">
                    Participante
                </h5>
            </Form.Group>
            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mb-2">Tela de resultado</p>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as={'textarea'}
                        name="json.result.title"
                        value={values.json.result.title}
                        onChange={handleChange}
                        isInvalid={!!errors?.json?.result?.title}
                        isValid={touched?.json?.result?.title && !errors.json?.result?.title}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.result?.title}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control
                        as={'textarea'}
                        name="json.result.description"
                        value={values.json.result.description}
                        onChange={handleChange}
                        isInvalid={!!errors?.json?.result?.description}
                        isValid={touched?.json?.result?.description && !errors.json?.result?.description}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.result?.description}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">
                    Modo de apresentação
                </h5>
            </Form.Group>
            <Row className="mx-0">
                <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="validationFormik01">
                    <Form.Label>Tamanho fonte (M) (rem)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.textSide.h5"
                        step={"0.01"}
                        value={values.json.apresentation?.textSide?.h5}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.apresentation?.textSide?.h5}
                        isValid={touched.json?.apresentation?.textSide?.h5 && !errors.json?.apresentation?.textSide?.h5}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.textSide?.h5}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                    controlId="validationFormik01">
                    <Form.Label>Tamanho fonte (P) (rem)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.textSide.p"
                        step={"0.01"}
                        value={values.json.apresentation.textSide.p}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.apresentation?.textSide?.p}
                        isValid={touched.json?.apresentation?.textSide?.p && !errors.json?.apresentation?.textSide?.p}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.textSide?.p}
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="col-md-4 mb-3">
                    <Form.Label>Cor de fundo e texto</Form.Label>
                    <div className={`d-flex justify-content-start align-items-center ${errors?.json?.apresentation?.textSide?.textColor || errors?.json?.apresentation?.textSide?.backgroundColor ? 'is-invalid' : ''}`}>
                        <Form.Control
                            type="hidden"
                            name="json.apresentation.textSide.backgroundColor"
                            value={values.json?.apresentation?.textSide?.backgroundColor}
                            isInvalid={!!errors?.json?.apresentation?.textSide?.backgroundColor}
                            isValid={touched?.json?.apresentation?.textSide?.backgroundColor && !errors?.json?.apresentation?.textSide?.backgroundColor}
                        />
                        <Form.Control
                            type="hidden"
                            name="json.apresentation.textSide.textColor"
                            value={values.json?.apresentation?.textSide?.textColor}
                            isInvalid={!!errors?.json?.apresentation?.textSide?.textColor}
                            isValid={touched?.json?.apresentation?.textSide?.textColor && !errors?.json?.apresentation?.textSide?.textColor}
                        />
                        <PopoverPicker
                            color={props.backgroundColor}
                            onChangeX={(e) => {
                                setFieldValue("json.apresentation.textSide.backgroundColor", e);
                                setProps(prevState => ({
                                    ...prevState,
                                    backgroundColor: e
                                }));
                            }}
                            classes={`me-1 picker ${errors?.json?.apresentation?.textSide?.backgroundColor ? 'is-invalid' : ''}`}
                        />
                        <PopoverPicker
                            color={props.textColor}
                            onChangeX={(e) => {
                                setFieldValue("json.apresentation.textSide.textColor", e);
                                setProps(prevState => ({
                                    ...prevState,
                                    textColor: e
                                }));
                            }}
                            classes={`me-1 picker ${errors?.json?.apresentation?.textSide?.textColor ? 'is-invalid' : ''}`}
                        />
                    </div>
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.apresentation?.textSide?.textColor ? errors?.json?.apresentation?.textSide?.textColor : errors?.json?.apresentation?.textSide?.backgroundColor ? errors?.json?.apresentation?.textSide?.backgroundColor : ''}
                    </Form.Control.Feedback>
                </div>
            </Row>
            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mt-3 mb-2">Logomarca</p>
                <Form.Group as={Col} sm={6} className='mb-3'>
                    <Form.Label>Largura (px)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.configs.logoSize"
                        value={values.json.apresentation.configs.logoSize}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.logoSize}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.logoSize}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mb-2">Tela inicial</p>
                <Form.Group
                    as={Col} sm={8} className='mb-3'
                    controlId="validationFormik01">
                    <Form.Label>Botão</Form.Label>
                    <Form.Control
                        type="text"
                        name="json.apresentation.firstPage.buttonText"
                        value={values.json.apresentation.firstPage.buttonText}
                        onChange={handleChange}
                        isInvalid={!!errors?.json?.apresentation?.firstPage?.buttonText}
                        isValid={touched?.json?.apresentation?.firstPage?.buttonText && !errors.json?.apresentation?.firstPage?.buttonText}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.apresentation?.firstPage?.buttonText}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={4} className='mb-3'>
                    <Form.Label>Posição (%)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.configs.buttonPosition"
                        value={values.json.apresentation.configs.buttonPosition}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.buttonPosition}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.buttonPosition}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mb-2">Tela preview</p>
                <Form.Group as={Col} sm={4} className='mb-3'>
                    <Form.Label>Posição (%)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.configs.buttonPositionPreview"
                        value={values.json.apresentation.configs.buttonPositionPreview}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.buttonPositionPreview}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.buttonPositionPreview}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mb-2">Tela do formulário</p>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as={'textarea'}
                        name="json.apresentation.register.title"
                        value={values.json?.apresentation?.register?.title}
                        onChange={handleChange}
                        isInvalid={!!errors?.json?.apresentation?.register?.title}
                        isValid={touched?.json?.apresentation?.register?.title && !errors.json?.apresentation?.register?.title}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.apresentation?.register?.title}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mb-2">Tela do resultado</p>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as={'textarea'}
                        name="json.apresentation.endPage.title"
                        value={values.json.apresentation.endPage.title}
                        onChange={handleChange}
                        isInvalid={!!errors?.json?.apresentation?.endPage?.title}
                        isValid={touched?.json?.apresentation?.endPage?.title && !errors.json?.apresentation?.endPage?.title}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.apresentation?.endPage?.buttonText}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                    controlId="validationFormik01"
                >
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control
                        as={'textarea'}
                        name="json.apresentation.endPage.description"
                        value={values.json.apresentation.endPage.description}
                        onChange={handleChange}
                        isInvalid={!!errors?.json?.apresentation?.endPage?.description}
                        isValid={touched?.json?.apresentation?.endPage?.description && !errors.json?.apresentation?.endPage?.description}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.apresentation?.endPage?.description}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="8"
                    controlId="validationFormik01"
                >
                    <Form.Label>Botão</Form.Label>
                    <Form.Control
                        type="text"
                        name="json.apresentation.endPage.buttonText"
                        value={values.json.apresentation.endPage.buttonText}
                        onChange={handleChange}
                        isInvalid={!!errors?.json?.apresentation?.endPage?.buttonText}
                        isValid={touched?.json?.apresentation?.endPage?.buttonText && !errors.json?.apresentation?.endPage?.buttonText}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.apresentation?.endPage?.buttonText}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={4} className='mb-3'>
                    <Form.Label>Posição (%)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.configs.buttonPositionEnd"
                        value={values.json.apresentation.configs.buttonPositionEnd}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.buttonPositionEnd}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.buttonPositionEnd}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={6} className='mb-3'>
                    <Form.Label>Finalizar fluxo automaticamente (milesimos)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.configs.qrcodeEndPage"
                        value={values.json.apresentation.configs.qrcodeEndPage}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.apresentation?.configs?.qrcodeEndPage}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.configs?.qrcodeEndPage}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">
                    Media <span className="small">(Tamanho máximo: 10mb)</span>
                </h5>
            </Form.Group>

            <Row className="mx-0">
                <Form.Group
                    as={Col}
                    md="6">
                    <Card className="mb-0">
                        <Card.Body>
                            <div className="text-center mb-3">
                                <div className="d-flex justify-content-center mb-3">
                                    <Form.Label>Frame vertical</Form.Label>
                                </div>
                            </div>
                            <Form.Control
                                type="hidden"
                                name="json.apresentation.frame"
                                value={values.json.apresentation.frame}
                                onChange={handleChange}
                                isInvalid={!!errors?.json?.apresentation?.frame}
                                isValid={touched?.json?.apresentation?.frame && !errors?.json?.apresentation?.frame}
                            />
                            <div className="imagesUpload">
                                <img
                                    className={`h-100 img-thumbnail ${!!errors?.json?.apresentation?.frame ? "is-invalid" : ""}`}
                                    src={
                                        values.json.apresentation.frame !== "" && values.json.apresentation.frame !== null
                                            ? domainConfig.imageServer +
                                            "/customizacao/" +
                                            values.customizacao_id +
                                            "/" +
                                            values.json.apresentation.frame
                                            : domainConfig.imageServer + "/empty-background.png"
                                    }
                                    alt="Imagem footer aplicativo"
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors?.json?.apresentation?.frame}
                                </Form.Control.Feedback>
                            </div>
                            <DropzoneComponent
                                className="mt-3 dropzoneComp"
                                type="image"
                                handlePreviewSuccess={handlePreviewSuccess}
                                handlePreviewError={handlePreviewError}
                                setFieldValue={setFieldValue}
                                folder="customizacao"
                                id={values.customizacao_id}
                                field="json.apresentation.frame"
                            />
                        </Card.Body>
                    </Card>
                </Form.Group>
                <Form.Group
                    as={Col}
                    md="6">
                    <Card className="mb-0">
                        <Card.Body>
                            <div className="d-flex justify-content-center mb-3">
                                <Form.Label>Frame horizontal (Maior que 1080px)</Form.Label>
                            </div>
                            <Form.Control
                                type="hidden"
                                name="json.apresentation.frameHorizontal"
                                value={values.json.apresentation?.frameHorizontal}
                                onChange={handleChange}
                                isInvalid={!!errors?.json?.apresentation?.frameHorizontal}
                                isValid={touched?.json?.apresentation?.frameHorizontal && !errors?.json?.apresentation?.frameHorizontal}
                            />
                            <div className="imagesUpload">
                                <img
                                    className={`h-100 img-thumbnail ${!!errors?.json?.apresentation?.frameHorizontal ? "is-invalid" : ""}`}
                                    src={
                                        values.json.apresentation?.frameHorizontal !== "" && values.json.apresentation?.frameHorizontal !== null
                                            ? domainConfig.imageServer +
                                            "/customizacao/" +
                                            values.customizacao_id +
                                            "/" +
                                            values.json.apresentation.frameHorizontal
                                            : domainConfig.imageServer + "/empty-background.png"
                                    }
                                    alt="Imagem footer aplicativo"
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors?.json?.apresentation?.frameHorizontal}
                                </Form.Control.Feedback>
                            </div>
                            <DropzoneComponent
                                className="mt-3 dropzoneComp"
                                type="image"
                                handlePreviewSuccess={handlePreviewSuccess}
                                handlePreviewError={handlePreviewError}
                                setFieldValue={setFieldValue}
                                folder="customizacao"
                                id={values.customizacao_id}
                                field="json.apresentation.frameHorizontal"
                            />
                        </Card.Body>
                    </Card>
                </Form.Group>
            </Row>
        </>
    );
};

export default Photobooth