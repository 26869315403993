import React, { useState, useEffect } from "react";
import { Oval } from 'react-loader-spinner'
import { useNavigate } from "react-router-dom";
import {
  eventList, setTitleEvent, selectEvents
} from "../redux/slices/events";
import { LAYOUT } from "../constants";
import useAppDispatch from "../hooks/useAppDispatch";
import useSettingsState from "../hooks/useSettingsState";

const initialState = {
  layout: LAYOUT.FLUID,
};

const LayoutContext = React.createContext(initialState);

function LayoutProvider({ children }) {
  const [layout, setLayout] = useSettingsState("layout", LAYOUT.FLUID);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(setTitleEvent(''));
      dispatch(eventList());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  return (
    <LayoutContext.Provider
      value={{
        layout,
        setLayout,
        setLoading
      }}
    >
      {children}
      {loading && <div className="loadingScreenProtection">
        <Oval
          visible={true}
          height="60"
          width="60"
          color={"#4ba700"}
          ariaLabel="oval-loading"
        /></div>}
    </LayoutContext.Provider>
  );
}

export { LayoutProvider, LayoutContext };
