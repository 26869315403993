import React from "react";
import { Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import Content from "../components/Content";

import dashboardItems from "../components/sidebar/dashboardItems";

const Dashboard = ({ children }) => {
  const state = useAuth();
  const items = dashboardItems(state); // Call the function to get the items

  return (
    <Wrapper>
      <Sidebar items={items} showFooter={false} />
      <Main>
        <Navbar />
        <Content>
          {children}
          <Outlet />
        </Content>
      </Main>
    </Wrapper>
  )
}

export default Dashboard;
