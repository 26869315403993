import React from "react";
import {
  Col,
  Form,
  Row,
  Modal,
  Badge,
  Card,
} from "react-bootstrap";
import { domainConfig } from "../config.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChalkboard,
  faChalkboardUser,
  faUsersLine,
  faUsers,
  faGamepad, faTrash, faPencil
} from "@fortawesome/free-solid-svg-icons";
import { fillMissingProperties } from '../assets/functions'
import { jsonCustomizacao } from "../components/Json.js";
import MemoryGame from '../components/apps/App01'
import Quiz from '../components/apps/App02'
import QA from '../components/apps/App03'
import MessageDisplay from '../components/apps/App04'
import WordsBoard from '../components/apps/App05'
import PrizeDraw from '../components/apps/App06'
import Roullete from '../components/apps/App07'
import Photobooth from '../components/apps/App10'

export const AplicativoCard = ({ value, dataHandler }) => (

  <Card className={`mainCard ${value.status === 0 ? 'disabled' : ''}`}>
    <div className={`card-top ${value.status === 0 ? 'bg-danger' : 'bg-primary'}`}>
      {(() => {
        switch (value.type) {
          case "Aplicativo":
            return (
              <>
                <FontAwesomeIcon icon={faGamepad} className="me-2" size="lg" />
                {value.type}
              </>
            );
          case "Social":
            return (
              <>
                <FontAwesomeIcon icon={faUsers} className="me-2" size="lg" />
                {value.type}
              </>
            );
          default:
            return "";
        }
      })()}
    </div>
    <div
      className={`p-3 clickMainCard`}
      onClick={() => {
        dataHandler(value);
      }}
    >
      <Card.Img
        className="px-3"
        src={domainConfig.imageServer + "/flex/painel/" + value.imagem}
        alt="aplicativoCard"
      />
      <h5 className="mt-2 mb-1">{value.nome}</h5>
    </div>
  </Card>
);

export const ApplicationsList = ({ aplicativos, setDataAplicativo }) => {

  function dataHandler(value) {
    setDataAplicativo(value);
  }

  return (
    <Row>
      {aplicativos.map((value, index) => {
        if (value.status === 0) {
          return ""
        }
        return (
          <Col key={"card" + index} lg="4" md="4" sm="6" xl="3">
            <AplicativoCard value={value} dataHandler={dataHandler} />
          </Col>
        );
      })}
    </Row>
  );
};

export const CustomizationsList = ({ customizations, setDataCustomizacao, deleteCustomization }) => {

  const handleDataCustomizacao = (value) => {
    let json = jsonCustomizacao(value.aplicativo_id)
    value.json = fillMissingProperties(value.json, json);
    setDataCustomizacao(value)
  }

  return (
    <div className="list-group aplicativo">
      {customizations.length > 0 ? (
        customizations.map((value, index) => {
          return (
            <div
              key={"gameMode" + index}
              className="list-group-item list-group-item-action mb-2"
              aria-current="true"
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">
                  {" "}
                  {value.titulo === "" ? (
                    <span className="text-muted">Sem título</span>
                  ) : (
                    value.titulo
                  )}
                </h5>
                <small>última edição: {value.data_edicao}</small>
              </div>
              {value.titulo === "" ? (
                <Badge bg={"danger"} className="me-2">
                  Rascunho
                </Badge>
              ) : (
                <Badge bg={"light"} className="text-primary me-2">
                  v.{value.version}
                </Badge>
              )}
              {deleteCustomization &&
                <button
                  type="button"
                  className="btn btn-sm btn-primary float-end me-0"
                  onClick={() =>
                    deleteCustomization(value["customizacao_id"])
                  }
                >
                  <FontAwesomeIcon icon={faTrash} size="1x" />
                </button>}
              {setDataCustomizacao &&
                <button
                  type="button"
                  className="btn btn-sm btn-primary float-end me-2"
                  onClick={() => handleDataCustomizacao(value)}
                >
                  <FontAwesomeIcon icon={faPencil} size="1x" />
                </button>}
            </div>
          );
        })
      ) : (
        <h5 className="py-5 text-center opacity-50">
          Nenhuma customização encontrada
        </h5>
      )}
    </div>
  );
};

export const GameType = ({
  dataAplicativo,
  createCustomization,
  showModalGameType,
  setShowModalGameType,
}) => {
  switch (dataAplicativo.aplicativo_id) {
    case 2:
      return (
        <>
          <Modal
            show={showModalGameType}
            size={"md"}
            onHide={setShowModalGameType}
            centered
            backdrop
          >
            <Modal.Body>
              <Form.Group as={Col}>
                <h4 className="mb-3 mt-2 text-center">Modo de jogo</h4>
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="row mx-0">
                  <div
                    className="gameTypeMode col-md-12 mt-0"
                    onClick={(e) => {
                      createCustomization(1);
                      setShowModalGameType(false);
                    }}
                  >
                    <div className="gameTypeModeIcon">
                      <div className="text-primary bg-light">
                        <FontAwesomeIcon icon={faChalkboard} />
                      </div>
                    </div>
                    <div className="gameTypeModeLabel">
                      <h5 className="mb-0">Individual</h5>
                      <span className="mb-0">
                        Nesse modo é possível responder a qualquer momento
                        durante a duração do evento
                      </span>
                    </div>
                  </div>
                  <div
                    className="gameTypeMode col-md-12"
                    onClick={(e) => {
                      createCustomization(2);
                      setShowModalGameType(false);
                    }}
                  >
                    <div className="gameTypeModeIcon">
                      <div className="text-primary bg-light">
                        <FontAwesomeIcon icon={faUsersLine} />
                      </div>
                    </div>
                    <div className="gameTypeModeLabel">
                      <h5 className="mb-0">Clássico</h5>
                      <span className="mb-0">
                        Os participantes progridem sozinhos e você acompanha os
                        resultados ao vivo
                      </span>
                    </div>
                  </div>
                  <div
                    className="gameTypeMode col-md-12"
                    onClick={(e) => {
                      createCustomization(3);
                      setShowModalGameType(false);
                    }}
                  >
                    <div className="gameTypeModeIcon">
                      <div className="text-primary bg-light">
                        <FontAwesomeIcon icon={faChalkboardUser} />
                      </div>
                    </div>
                    <div className="gameTypeModeLabel">
                      <h5 className="mb-0">Apresentador</h5>
                      <span className="mb-0">
                        Controle o ritmo para que todos avancem em cada pergunta
                        juntos.
                      </span>
                    </div>
                  </div>
                </div>
              </Form.Group>
            </Modal.Body>
          </Modal>
        </>
      );
    default:
      return "";
  }
};

export const AppDefinitions = ({ fonts,aplicativo_id, handleChange, values, setFieldValue, errors, touched, handlePreviewSuccess, handlePreviewError }) => {

  switch (aplicativo_id) {
    case 1:
      return (
        <MemoryGame
          handleChange={handleChange}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          handlePreviewSuccess={handlePreviewSuccess}
          handlePreviewError={handlePreviewError}
        />
      );
    case 2:
      return (
        <Quiz
          handleChange={handleChange}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          handlePreviewSuccess={handlePreviewSuccess}
          handlePreviewError={handlePreviewError}
        />
      );
    case 3:
      return (
        <QA
          fonts={fonts}
          handleChange={handleChange}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          handlePreviewSuccess={handlePreviewSuccess}
          handlePreviewError={handlePreviewError}
        />
      );
    case 4:
      return (
        <MessageDisplay
          fonts={fonts}
          handleChange={handleChange}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          handlePreviewSuccess={handlePreviewSuccess}
          handlePreviewError={handlePreviewError}
        />
      );
    case 5:
      return (
        <WordsBoard
          fonts={fonts}
          handleChange={handleChange}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          handlePreviewSuccess={handlePreviewSuccess}
          handlePreviewError={handlePreviewError}
        />
      );
    case 6:
      return (
        <PrizeDraw
          fonts={fonts}
          handleChange={handleChange}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          handlePreviewSuccess={handlePreviewSuccess}
          handlePreviewError={handlePreviewError}
        />
      )
    case 7:
      return (
        <Roullete
          handleChange={handleChange}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          handlePreviewSuccess={handlePreviewSuccess}
          handlePreviewError={handlePreviewError}
        />
      )
    case 10:
      return (
        <Photobooth
          handleChange={handleChange}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          touched={touched}
          handlePreviewSuccess={handlePreviewSuccess}
          handlePreviewError={handlePreviewError}
        />
      )
    default:
      return <h5>Nenhum componente encontrado</h5>;
  }
}

