import React, { forwardRef } from "react";
import { Form } from "react-bootstrap";
import { format } from "date-fns-tz";
import { parse, isValid, add, isAfter } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function redirectURL(open, url) {
  if (open === true) {
    window.open(url, "_blank");
  } else {
    window.location.replace(url);
  }
}

export const isColor = (strColor) => {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== "";
};

export function convertMilliseconds(milliseconds) {
  const minutes = Math.floor(milliseconds / 60000);
  const seconds = Math.floor((milliseconds % 60000) / 1000);
  const remainingMilliseconds = milliseconds % 1000;

  return {
    minutes: String(minutes).padStart(2, '0'),
    seconds: String(seconds).padStart(2, '0'),
    milliseconds: String(remainingMilliseconds).padStart(3, '0')
  };
}

export function handleYoutubeUrl(value) {
  let regex =
    /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
  let id = regex.exec(value);
  if (id && id[3]) {
    return id[3];
  } else {
    return;
  }
}

export const HelpTooltip = forwardRef((props, ref) => (
  <span ref={ref} className="ms-1 cursor-pointer">
    <FontAwesomeIcon icon={props.icon} {...props} />
  </span>
));

// Date handlers
export function validateDate(date) {
  // Attempt to parse with seconds
  const parsedDateWithSeconds = parse(date, "dd/MM/yyyy HH:mm:ss", new Date());
  const isValidWithSeconds = isValid(parsedDateWithSeconds);

  // Attempt to parse without seconds
  const parsedDateWithoutSeconds = parse(date, "dd/MM/yyyy HH:mm", new Date());
  const isValidWithoutSeconds = isValid(parsedDateWithoutSeconds);

  // Return true if either parse is valid
  if (isValidWithSeconds || isValidWithoutSeconds) {
    return true;
  }
  return false;
}

export function isDateAfterToday(date) {
  const parsedDateWithSeconds = parse(date, "dd/MM/yyyy HH:mm:ss", new Date());
  const parsedDateWithoutSeconds = parse(date, "dd/MM/yyyy HH:mm", new Date());

  const validParsedDate = isValid(parsedDateWithSeconds) ? parsedDateWithSeconds : parsedDateWithoutSeconds;

  if (isValid(validParsedDate)) {
    return isAfter(validParsedDate, new Date());
  }
  return false;
}

export function generateDateCategories() {
  const monthsInPortuguese = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
  const today = new Date();
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const daysInMonth = endOfMonth.getDate();
  const categories = [];

  // Helper function to format the date
  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = monthsInPortuguese[date.getMonth()];
    return `${day} ${month}`;
  }

  // Generate categories for each day of the current month
  for (let i = 1; i <= daysInMonth; i++) {
    const newDate = new Date(today.getFullYear(), today.getMonth(), i);
    categories.push(formatDate(newDate));
  }

  return categories;
}

export function safeJSONParse(value) {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value; // If parsing fails, return the original value
  }
}

export function convertDate(date) {
  const parsedDateTime = parse(date, "dd/MM/yyyy HH:mm", new Date());
  const dateX = new Date(parsedDateTime);
  const datetime = format(dateX, "yyyy-MM-dd HH:mm");
  return datetime;
}

export function convertDateToDisplay(date, dateOnly = false) {
  const dateX = new Date(date);
  let datetime;
  if (dateOnly === true) {
    datetime = format(dateX, "dd/MM/yyyy");
  } else {
    datetime = format(dateX, "dd/MM/yyyy HH:mm");
  }
  return datetime;
}

// Table filters
export function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    if (!preFilteredRows.length) {
      return [0, 0]; // No rows, return default min/max
    }

    // Initialize min and max with the first valid value
    let min = Number(preFilteredRows[0].values[id]) || 0;
    let max = min;

    preFilteredRows.forEach((row) => {
      const value = Number(row.values[id]);
      if (!isNaN(value)) {
        min = Math.min(value, min);
        max = Math.max(value, max);
      }
    });

    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div className="d-flex mt-2">
      <Form.Control
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "110px",
        }}
      />
      <span className="mx-2 mt-1">até</span>
      <Form.Control
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "110px",
        }}
      />
    </div>
  );
}

export function defaultCreationDate() {
  const data_criacaoObj = new Date();
  const data_criacaoObjX = add(data_criacaoObj, { minutes: "15" });
  const date = format(data_criacaoObjX, "yyyy-MM-dd HH:mm:ss");
  return date;
}

export function defaultEndDate() {
  const data_terminoObj = new Date();
  const data_terminoObjX = add(data_terminoObj, { days: "7" });
  const date = format(data_terminoObjX, "yyyy-MM-dd HH:ss");
  return date;
}

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);
  return (
    <Form.Select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Todos</option>
      {options.map((option, i) => {
        if (option !== '' && option !== null && option !== undefined) {
          return (
            <option key={i} value={option}>
              {option}
            </option>
          )
        } else {
          return ''
        }
      })}
    </Form.Select>
  );
}

export function handleShareLink(domain, values, type = false, notyf) {
  let fullDomain;
  switch (type) {
    case "presenter":
      fullDomain =
        domain +
        "/?token=" +
        values.token +
        "&presenter=" +
        values.codigo_presenter;
      break;
    default:
      fullDomain =
        domain + "/?token=" + values.token;
      break;
  }
  navigator.clipboard.writeText(fullDomain);
  notyf.open({
    type: "success",
    message: "Link copiado para a área de transferência",
    ripple: true,
    dismissible: true,
  });
}

export function fillMissingProperties(baseObject, comparisonObject) {
  for (const key in comparisonObject) {
    if (comparisonObject.hasOwnProperty(key)) {
      const isObject = typeof comparisonObject[key] === 'object' && !Array.isArray(comparisonObject[key]);
      const isArray = Array.isArray(comparisonObject[key]);

      if (isObject) {
        // If baseObject[key] is not an object, replace it with an empty object
        if (!baseObject[key] || typeof baseObject[key] !== 'object' || Array.isArray(baseObject[key])) {
          baseObject[key] = {};
        }
        fillMissingProperties(baseObject[key], comparisonObject[key]);
      } else if (isArray) {
        // If baseObject[key] is not an array, replace it with an empty array
        if (!baseObject[key] || !Array.isArray(baseObject[key])) {
          baseObject[key] = [];
        }
        // Optional: You can implement logic to merge arrays if needed
      } else {
        // Only add the property if it doesn't exist in the baseObject
        if (baseObject[key] === undefined) {
          baseObject[key] = comparisonObject[key];
        }
      }
    }
  }
  return baseObject;
}

export function generateSixDigitCode() {
  let code = '';
  for (let i = 0; i < 6; i++) {
    const randomDigit = Math.floor(Math.random() * 10); // Generate a random digit between 0 and 9
    code += randomDigit;
  }
  return parseInt(code);
}

// Utility function to strip units from the value
export const stripUnit = (value) => value.replace(/(px|rem)$/, '');

// Utility function to append units to the value
export const appendUnit = (value, unit) => `${value}${unit}`;

export const returnDrawType = (value) => {
  switch (value) {
    case `DrawDefault`:
      return (
        "Apenas participantes"
      );
    case `DrawName`:
      return (
        "Sorteio por nomes"
      );
    case `DrawNumber`:
      return (
        "Sorteio por números"
      );

    default:
      return ("");
  }
}